<template>
  <v-dialog
    persistent
    v-model="active"
    :width="screenWidth + '%'"
  >
    <div class="container-dialog">
      <close-dialog-component @closeDialog="closeDialog" />
      <p class="text-title mon-bold">{{ texts.rate.textTitle }}</p>
      <div class="mt-5">
        <div class="display-flex align-items-center justify-content-center">
          <div class="content-image">
            <img
              src="@/assets/images/rating.png"
              alt=""
            >
          </div>
        </div>
        <div class="mt-9 display-flex align-items-center justify-content-center">
          <p class="text-description mon-regular">{{ texts.rate.textDescription }}</p>
        </div>
        <div class="display-flex align-items-center justify-content-center">
          <v-rating
            color="#ffc92a"
            background-color="#c6c6c8"
            length="5"
            size="35"
            v-model="qualify"
          ></v-rating>
        </div>
      </div>
      <div
        v-if="isResponsive"
        class="mt-9"
      >
        <v-btn
          @click="qualifyOrder"
          :disabled="!validateForm"
          :loading="bLoading"
          elevation="0"
          class="button-confirm mon-bold"
          style="width: 100%;"
        >
          {{ texts.rate.buttons.send }}
        </v-btn>
        <v-btn
          @click="closeDialog"
          elevation="0"
          class="button-cancel mon-regular mt-5"
          style="width: 100%;"
        >
          {{ texts.rate.buttons.cancel }}
        </v-btn>
      </div>
      <div
        v-else
        class="display-flex align-items-center justify-content-flex-end mt-9"
      >
        <v-btn
          @click="closeDialog"
          elevation="0"
          class="button-cancel mon-regular"
        >
          {{ texts.rate.buttons.cancel }}
        </v-btn>
        <v-btn
          @click="qualifyOrder"
          :disabled="!validateForm"
          :loading="bLoading"
          elevation="0"
          class="button-confirm mon-bold ml-2"
        >
          {{ texts.rate.buttons.send }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "OrderDetailRateLayout",
  data() {
    return {
      //VARIABLES
      screenWidth: 0,
      texts: "",
      qualify: 0,
      bLoading: false,
      isResponsive: false,
    };
  },
  beforeMount() {
    this.texts = FILE.rate[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    qualifyOrder: function () {
      this.bLoading = true;

      const payload = {
        sDescription: "N/A",
        sPurchaseOrderStatusId: "18b984e7-2295-40d8-aaa1-8f4f89fdaed8", //finalizado
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/status/purchase-orders/${this.purchaseOrderId}`,
        payload,
        config
      )
        .then((response) => {
          this.sendRating(response.data.message);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    sendRating: function (message) {
      const payload = {
        dRating: this.qualify,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/sales-orders/${this.salesOrderId}/rating`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.mixSuccess(message);
          this.closeDialog();
          this.$router.push("/order/tab/2");
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 50;
      } else {
        this.screenWidth = 100;
      }

      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    closeDialog: function () {
      this.$store.commit("setRateOrder", {
        active: false,
        purchaseOrderId: "",
        salesOrderId: "",
        vendorName: "",
      });
      this.qualify = 0;
    },
  },
  computed: {
    active: function () {
      return this.$store.state.rateOrderActive;
    },
    purchaseOrderId: function () {
      return this.$store.state.ratePurchaseOrderId;
    },
    salesOrderId: function () {
      return this.$store.state.rateSalesOrderId;
    },
    vendorName: function () {
      return this.$store.state.rateVendorName;
    },
    selectLanguage: function () {
      return this.$store.state.language;
    },
    validateForm: function () {
      return this.qualify !== 0;
    },
  },
  watch: {
    active: function () {
      if (this.active) {
        this.texts = FILE.rate[this.selectLanguage];
      }
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.rate[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.button-confirm:disabled {
  opacity: 0.5;
  cursor: no-drop !important;
  pointer-events: all !important;
}

.text-description {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  width: 85%;
}

.content-image {
  width: 120px;
  height: 120px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 20px;
  }
}

.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}

.text-title {
  text-align: center;
  font-size: 25px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.button-cancel {
  height: 40px !important;
  background-color: #ffffff !important;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.button-confirm {
  height: 40px !important;
  background: transparent linear-gradient(257deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}
</style>